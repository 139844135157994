<template>
  <vx-card :title="title">
    <div class="vx-row w-1/2 mb-6 mt-3 ml-5">
      <vs-button
        class="mt-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
      >Create</vs-button
      >
      <vs-button
        class="mt-2 ml-5"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-upload"
        @click="handleUpload()"
      >Import</vs-button
      >
    </div>
    <div class="vx-col w-1/2 mb-6 mt-3 ml-5">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/4 w-full">
          <span>TOP Model</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <multiselect
            class="selectExample"
            v-model="selectedTopModel"
            :options="OptionTopModel"
            :multiple="false"
            :filterable="true"
            :allow-empty="true"
            :group-select="true"
            :max-height="100"
            :limit="3"
            :internal-search="false"
            placeholder="Type to search"
            :searchable="true"
            track-by="value"
            label="name"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/4 w-full">
          <span>Supplier</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <multiselect
            class="selectExample"
            v-model="selectedSupplier"
            :options="optionSupplier"
            :multiple="false"
            :filterable="true"
            :allow-empty="true"
            :group-select="true"
            :max-height="100"
            :limit="3"
            :internal-search="false"
            placeholder="Type to search"
            :searchable="true"
            track-by="ID"
            @search-change="handleSearchSupplier"
            :custom-label="customLableSupplier"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/4 w-full">
          <span>Distribution Channel</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <multiselect
            class="selectExample"
            v-model="selectedCustomerCategory"
            :options="optionCustomerCategory"
            multiple
            :filterable="true"
            :allow-empty="true"
            :group-select="true"
            :max-height="100"
            :limit="3"
            :internal-search="true"
            placeholder="Type to search"
            :searchable="true"
            track-by="ID"
            label="classification"
          />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col sm:w-2/3 w-full">
          <span></span>
        </div>
        <vs-button
          @click="onClickBtnFilter"
          size="medium"
          color="primary"
          icon-pack="feather"
          icon="icon-search"
          title="Search"
        >
          Filter
        </vs-button>
        <vs-button
          @click="onClickBtnClear"
          size="medium"
          class="ml-2"
          color="primary"
          icon-pack="feather"
          icon="icon-search"
          title="Search"
        >
          Clear
        </vs-button>
      </div>
    </div>
    <vs-tabs>
      <vs-tab label="Draf">
        <Draf
          :selectedTopModels="selectedTopModel"
          :selectedSuppliers="selectedSupplier"
          :customerCategory="selectedCustomerCategory"
          :isFilters="isfilter"
          @isFilters="setFilter"
        ></Draf>
      </vs-tab>
      <vs-tab label="Waiting">
        <Waiting
          :selectedTopModels="selectedTopModel"
          :selectedSuppliers="selectedSupplier"
          :customerCategory="selectedCustomerCategory"
          :isFilters="isfilter"
          @isFilters="setFilter"
        ></Waiting>
      </vs-tab>
      <vs-tab label="Inquiry">
        <Inquiry
          :selectedTopModels="selectedTopModel"
          :selectedSuppliers="selectedSupplier"
          :customerCategory="selectedCustomerCategory"
          :isFilters="isfilter"
          @isFilters="setFilter"
        ></Inquiry>
      </vs-tab>
      <vs-tab label="Approved">
        <Approved
          :selectedTopModels="selectedTopModel"
          :selectedSuppliers="selectedSupplier"
          :customerCategory="selectedCustomerCategory"
          :isFilters="isfilter"
          @isFilters="setFilter"
        ></Approved>
      </vs-tab>
      <vs-tab label="Released">
        <Released
          :selectedTopModels="selectedTopModel"
          :selectedSuppliers="selectedSupplier"
          :customerCategory="selectedCustomerCategory"
          :isFilters="isfilter"
          @isFilters="setFilter"
        ></Released>
      </vs-tab>
      <vs-tab label="Canceled">
        <Canceled
          :selectedTopModels="selectedTopModel"
          :selectedSuppliers="selectedSupplier"
          :customerCategory="selectedCustomerCategory"
          :isFilters="isfilter"
          @isFilters="setFilter"
        ></Canceled>
      </vs-tab>
      <vs-tab label="Rejected">
        <Rejected
          :selectedTopModels="selectedTopModel"
          :selectedSuppliers="selectedSupplier"
          :customerCategory="selectedCustomerCategory"
          :isFilters="isfilter"
          @isFilters="setFilter"
        ></Rejected>
      </vs-tab>
      <vs-tab label="Inactive">
        <Inactive
          :selectedTopModels="selectedTopModel"
          :selectedSuppliers="selectedSupplier"
          :customerCategory="selectedCustomerCategory"
          :isFilters="isfilter"
          @isFilters="setFilter"
        ></Inactive>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Draf from "./_tab/tab-new.vue";
import Waiting from "./_tab/tab-waiting.vue";
import Inquiry from "./_tab/tab-inquiry.vue";
import Approved from "./_tab/tab-approved.vue";
import Released from "./_tab/tab-released.vue";
import Canceled from "./_tab/tab-canceled.vue";
import Rejected from "./_tab/tab-rejected.vue";
import Inactive from "./_tab/tab-inactive.vue";
export default {
  components: {
    Draf,
    Waiting,
    Inquiry,
    Approved,
    Released,
    Canceled,
    Rejected,
    Inactive,
  },
  data() {
    return {
      title: "Product Festives",
      selectedTopModel: {name: "", value: ""},
      OptionTopModel: [
        {name: "TOP", value: "TOP"},
        {name: "FDD", value: "FDD"},
      ],
      selectedSupplier: {Name: "", Code: "", ID: 0},
      optionSupplier: [],
      selectedCustomerCategory: [],
      optionCustomerCategory: [],
      isfilter: false,
    };
  },
  methods: {
    setFilter(val) {
      this.isfilter = val;
    },
    handleCreate() {
      this.$router.push({
        name: "product-festives-create",
        // params: { id: id },
      });
    },
    handleUpload() {
      this.$router.push({
        name: "product-festives-import",
        // params: { id: id },
      });
    },
    getSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/productFestives/supplier", {
          params: {
            length: 10,
            search: this.searchSup,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionSupplier = resp.data.records;

              if (this.optionSupplier.length > 0) {
                // this.selectedSupplier = this.optionSupplier[0];
              } else {
                this.optionSupplier = [];
                this.selectedSupplier = {};
              }
            } else {
              this.optionSupplier = [];
              this.selectedSupplier = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerCategory() {
      this.$http
        .get("/api/v1/master/customer-category-all", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.optionCustomerCategory = resp.data;
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    customLableSupplier({Code, Name}) {
      return `${Code}-${Name}`;
    },

    handleSearchSupplier(searching) {
      this.optionSupplier = [];
      this.searchSup = searching;
      this.getSupplier();
    },
    onClickBtnFilter() {
      console.log("disini");
      this.isfilter = true;
    },
    onClickBtnClear() {
      this.selectedSupplier = {Code: "", Name: "", ID: 0};
      this.selectedTopModel = {};
      this.selectedCustomerCategory = [];
      this.isfilter = true;
    },
  },
  mounted() {
    // this.getData();
    this.getSupplier();
    this.getCustomerCategory();
  },
};
</script>
