<template>
  <div class="tab-text">
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          mutualAction == 'edit' || mutualAction == 'create' ? detailShow : '',
          detailHide,
        ]"
      >
        <data-table
          :baseUrl="baseUrl"
          @id="setId"
          @action="setAction"
          :action="mutualAction"
          :status="4"
          :supplier="selectedSuppliers.ID"
          :customerCategory="customerCategory"
          :topmodel="selectedTopModels.value"
          :isFilter="isFilters"
          @isFilter="setFilter"
          :validTo="today"
          :dateCondition="NotExpired"
        ></data-table>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "../DataTable";
// import DataForm from "./DataForm";
export default {
  props: {
    selectedTopModels: Object,
    selectedSuppliers: Object,
    customerCategory: Object,
    isFilters: Boolean,
  },
  components: {
    DataTable,
    // DataForm,
  },
  data() {
    return {
      title: "Product Festives",
      baseUrl: "/api/v1/master/productFestives",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      mutualAction: "",
      id: null,
      NotExpired: "NotExpired"
    };
  },
  methods: {
    setFilter(val) {
      this.$emit("isFilters", false);
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.mutualAction = "";
    },
    setId(val) {
      this.id = val;
    },
    setAction(val) {
      this.mutualAction = val;
    },
  },
  mounted() {
    // this.getData();
  },
  watch: {
    isfilters: {
      handler: function (val) {
        console.log("isfilters", val);
      },
      deep: true,
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    today() {
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1; // JavaScript months are 0-based counting
      let year = date.getFullYear();

      // if day or month is less than 10, add a leading 0 to it.
      if (day < 10) day = '0' + day;
      if (month < 10) month = '0' + month;

      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  /* min-height: 800px; */
  padding: 5px;
}
</style>
